
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import Summary from './components/summary.vue'
import GroupScrollerBar, { GroupInfo } from './components/group-scroller-bar.vue'
import ProjectFolderGroup from './components/project-folder-group.vue'
import ProjectGroupApi from '@/api/project-group/project-group-api'
import { PageListData, PagerQueryBean } from '@/libs/http-request'
import { ProjectGroup, ProjectGroupOfFront } from '@/api/project-group/project-group'
import _ from 'lodash'
import { UserProjectGroupStar } from '@/api/user-project-group-star/user-project-group-star'
import UserProjectGroupStarApi from '@/api/user-project-group-star/user-project-group-star-api'
import { getterUserInfo } from '@/store'
import moment from 'moment'

const defaultQuery = {
  page: 1,
  pageSize: 10,
  sorts: ['-createTime'],
  nameAtTeam: undefined,
  userPhone: undefined,
  dateRage: undefined
}

const letters = ['ABC', 'DEF', 'GHI', 'JKL', 'MNO', 'PQR', 'STU', 'VWX', 'ZY', '其他']

@Component({
  name: 'MyProject',
  components: { Summary, GroupScrollerBar, ProjectFolderGroup }
})
export default class MyProject extends Vue {
    isShowSpin = false
    folderGroupList: Array<GroupInfo> = []
    canAddGroupFlag = false

    /// 查询数据
    queryData: PagerQueryBean=_.cloneDeep(defaultQuery)

    pageData: PageListData<ProjectGroup>={
      items: [],
      total: 0,
      pageSize: 10000
    }

    @Watch('$route.path')
    routeChange (newValue: string) {
      this.loadDataFromServer()
      if (newValue === '/project/my-project') this.canAddGroupFlag = true
      else this.canAddGroupFlag = false
    }

    /** 是否开通项目模块 */
    get isOpeningProjectModule () {
      const user = getterUserInfo()
      const openWebApi = user.webApiExpirationTime && moment(user.webApiExpirationTime).isAfter(moment.now())
      const openWebFront = user.webFrontExpirationTime && moment(user.webFrontExpirationTime).isAfter(moment.now())
      return openWebApi || openWebFront
    }

    onSelectGroup (groupInfo: any) {
      const groupDom = document.querySelector('#group-id-' + groupInfo.groupTag)
      if (groupDom) {
        groupDom.scrollIntoView(true)
      }
    }

    mounted () {
      this.loadDataFromServer()
      if (this.$route.path === '/project/my-project') this.canAddGroupFlag = true
      else this.canAddGroupFlag = false
    }

    onAddNewProjectGroup () {
      this.loadDataFromServer()
    }

    onDeleteProjectGroup () {
      this.loadDataFromServer()
    }

    onUpdateProjectGroup () {
      this.loadDataFromServer()
    }

    onLockOrUnLockProjectGroup () {
      this.loadDataFromServer()
    }

    onStarOrUnStarProjectGroup () {
      this.loadDataFromServer()
    }

    loadDataFromServer (): Promise<any> {
      this.isShowSpin = true
      return ProjectGroupApi
        .projectGroupListOfCurTeam(this.queryData)
        .then(resp => {
          const pageData = resp.data!
          this.pageData = pageData
          return UserProjectGroupStarApi.userProjectGroupStarListOfCurTeam(_.cloneDeep(defaultQuery))
        })
        .then(resp => {
          const starList = resp.data
          const groupInfoList = this.createGroupInfoList()
          this.wrapGroupInfoListForCount(groupInfoList, this.pageData.items)
          if (starList) {
            this.wrapGroupInfoListForStar(starList, this.pageData.items)
          }
          this.wrapGroupInfoListForStarCount(groupInfoList, this.pageData.items)

          // 我的项目
          if (this.$route.path === '/project/my-project') {
            this.groupInfoList = groupInfoList
            this.folderGroupList = this.groupInfoList.filter(groupInfo => groupInfo.count > 0)
          } else if (this.$route.path === '/project/my-project-start') {
            // 我的关注项目
            this.groupInfoList = groupInfoList.map(groupInfo => {
              const starProjectGroups = groupInfo.projectGroups.filter((projectGroup: ProjectGroupOfFront) => projectGroup.stared)
              return {
                groupTag: groupInfo.groupTag,
                starCount: groupInfo.starCount,
                projectGroups: starProjectGroups,
                count: starProjectGroups.length
              }
            })
            this.folderGroupList = this.groupInfoList.filter(groupInfo => groupInfo.count > 0)
          } else if (this.$route.path === '/project/my-project-close') {
            // 我的已关闭项目
            this.groupInfoList = []
            this.folderGroupList = []
          }
        })
        .finally(() => {
          this.isShowSpin = false
        })
    }

    groupInfoList: Array<GroupInfo> = []
    createGroupInfoList () {
      const groupInfoList = letters.map(letter => {
        const count = 0
        const starCount = 0
        return {
          groupTag: letter,
          count: count,
          starCount: starCount,
          projectGroups: []
        } as GroupInfo
      })
      return groupInfoList
    }

    wrapGroupInfoListForCount (groupInfoList: Array<GroupInfo>, projectGroups: Array<ProjectGroup>) {
      projectGroups.forEach(projectGroup => {
        let groupInfo = groupInfoList.find(groupInfo => groupInfo.groupTag.indexOf(projectGroup.firstLetter!) !== -1)
        if (!groupInfo) {
          groupInfo = groupInfoList.find(groupInfo => groupInfo.groupTag.indexOf('其他') !== -1)
        }
        if (groupInfo) {
          groupInfo.count = groupInfo.count + 1
          groupInfo.projectGroups.push(projectGroup)
        }
      })
    }

    wrapGroupInfoListForStarCount (groupInfoList: Array<GroupInfo>, projectGroups: Array<ProjectGroupOfFront>) {
      projectGroups.forEach(projectGroup => {
        if (!projectGroup.stared) {
          return
        }
        const groupInfo = groupInfoList.find(groupInfo => groupInfo.groupTag.indexOf(projectGroup.firstLetter!) !== -1)
        if (groupInfo) {
          groupInfo.starCount = groupInfo.starCount + 1
        }
      })
    }

    wrapGroupInfoListForStar (starList: Array<UserProjectGroupStar>, projectGroups: Array<ProjectGroup>): void {
      starList.forEach(star => {
        const projectGroup = projectGroups.find(projectGroup => projectGroup.id === star.projectGroupId) as ProjectGroupOfFront
        if (projectGroup) {
          projectGroup.stared = true
        }
      })
    }
}
