
import { ProjectGroupOfFront } from '@/api/project-group/project-group'
import ProjectGroupApi from '@/api/project-group/project-group-api'
import { Prop, Vue, Component } from 'vue-property-decorator'
import Modals from '@/views/project/my-project/data-list/modal/modals'
import UserProjectGroupStarApi from '@/api/user-project-group-star/user-project-group-star-api'
import { getterUserInfo } from '@/store'
import moment from 'moment'

@Component({
  name: 'ProjectFolder',
  components: {}
})
export default class ProjectFolder extends Vue {
  // 鼠标状态 进入enter 离开leave
  mouseState = 'leave'

  @Prop({
    type: String,
    default: () => require('@/assets/project/folder-image.png')
  })
  folderImageSrc?: string

  @Prop({
    type: String,
    default: '未知'
  })
  folderName?: string

  @Prop({
    type: Object
  })
  folder!: ProjectGroupOfFront

  @Prop({
    type: Boolean,
    default: false
  })
  canStar?: boolean

  /** 是否开通项目模块 */
  get isOpeningProjectModule () {
    const user = getterUserInfo()
    const openWebApi = user.webApiExpirationTime && moment(user.webApiExpirationTime).isAfter(moment.now())
    const openWebFront = user.webFrontExpirationTime && moment(user.webFrontExpirationTime).isAfter(moment.now())
    return openWebApi || openWebFront
  }

  get isShowActionIcons () {
    return this.mouseState === 'enter'
  }

  get isShowLockIcons () {
    return this.folder.locked === true || this.isShowActionIcons
  }

  get isShowStarIcons () {
    return this.folder.stared === true || this.isShowActionIcons
  }

  get starIconSrc () {
    return this.folder.stared
      ? require('@/assets/project/folder-star-actived.png')
      : require('@/assets/project/folder-star-disabled.png')
  }

  get lockIconSrc () {
    return this.folder.locked
      ? require('@/assets/project/folder-lock-actived.png')
      : require('@/assets/project/folder-lock-disabled.png')
  }

  get editIconSrc () {
    return this.canEdit
      ? require('@/assets/project/folder-edit-actived.png')
      : require('@/assets/project/folder-edit-disabled.png')
  }

  get deleteIconSrc () {
    return this.canDelete
      ? require('@/assets/project/folder-delete-actived.png')
      : require('@/assets/project/folder-delete-disabled.png')
  }

  onMouseEnter () {
    this.mouseState = 'enter'
  }

  onMouseLeave () {
    this.mouseState = 'leave'
  }

  onClickDelete () {
    if (!this.folder) {
      return
    }
    this.$Modal.confirm({
      title: '是否删除？',
      content: `是否删除${this.folder.name}？`,
      onOk: () => {
        ProjectGroupApi.deleteItemByIds([this.folder!.id!])
          .then(resp => {
            this.$Message.info('操作成功')
            this.$emit('on-delete-project-group')
          })
          .catch(err => {
            this.$Message.error({
              content: err.message
            })
          })
      }
    })
  }

  onClickLock () {
    if (!this.canLock) return
    let operationName = '锁定'
    if (this.folder.locked) {
      operationName = '解锁'
    }
    this.$Modal.confirm({
      title: `是否${operationName}？`,
      content: `是否${operationName}${this.folder.name}？`,
      onOk: () => {
        ProjectGroupApi.lockOrUnLock(this.folder)
          .then(resp => {
            this.$Message.info('操作成功')
            this.$emit('on-lock-or-unlock-project-group')
          })
          .catch(err => {
            this.$Message.error({
              content: err.message
            })
          })
      }
    })
  }

  onClickEdit () {
    const that = this
    Modals.showProjectGroupUpdaterModal(this.folder!, data => {
      return new Promise<void>((resolve, reject) => {
        ProjectGroupApi.updateItem(data)
          .then(res => {
            this.$Message.success('操作成功')
            that.$emit('on-update-project-group')
            resolve()
          })
          .catch(err => {
            this.$Message.error({
              content: err.message
            })
          })
      })
    })
  }

  onClickStar () {
    let operationName = '标星'
    if (this.folder.stared) {
      operationName = '取消标星'
    }
    this.$Modal.confirm({
      title: `是否${operationName}？`,
      content: `是否${operationName}${this.folder.name}？`,
      onOk: () => {
        if (this.folder.stared) {
          this.unStar()
          return
        }
        this.star()
      }
    })
  }

  star () {
    UserProjectGroupStarApi.star({
      projectGroupId: this.folder.id
    })
      .then(resp => {
        this.$Message.info('操作成功')
        this.$emit('on-star-or-unstar-project-group')
      })
      .catch(err => {
        this.$Message.error({
          content: err.message
        })
      })
  }

  unStar () {
    UserProjectGroupStarApi.unStar({
      projectGroupId: this.folder.id
    })
      .then(resp => {
        this.$Message.info('操作成功')
        this.$emit('on-star-or-unstar-project-group')
      })
      .catch(err => {
        this.$Message.error({
          content: err.message
        })
      })
  }

  onClickProjectGroup () {
    const that = this
    that.$emit('on-click-project-group')
  }

  /**
   * 当前用户的项目编辑权限
   */
  get canEdit () {
    const user = getterUserInfo()
    return user.canCreateProjectGroup
  }

  get canDelete () {
    const user = getterUserInfo()
    return user.canDeleteProjectGroup
  }

  get canLock () {
    const user = getterUserInfo()
    return user.canLockProjectGroup
  }
}
