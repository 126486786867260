
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import Modals from '@/views/project/my-project/data-list/modal/modals'
import ProjectGroupApi from '@/api/project-group/project-group-api'
import { getterUserInfo } from '@/store'
import moment from 'moment'

@Component({
  name: 'Summary',
  components: { }
})
export default class Summary extends Vue {
  @Prop({
    type: Number,
    default: 0
  })
  projectCount!: number

  @Prop({
    type: Boolean,
    required: true
  })
  canAddGroupFlag!: boolean

  onClickAddNewProjectGroupButton () {
    const that = this
    Modals.showProjectGroupCreatorModal(data => {
      return new Promise<void>((resolve, reject) => {
        ProjectGroupApi
          .insertItem(data)
          .then(res => {
            this.$Message.success('操作成功')
            that.$emit('on-add-new-project-group')
            resolve()
          })
          .catch(err => {
            this.$Message.error({
              content: err.message
            })
          })
      })
    })
  }

  /**
   * 是否开通权限
   */
  get openRight () {
    const user = getterUserInfo()
    const openWebApi = user.webApiExpirationTime && moment(user.webApiExpirationTime).isAfter(moment.now())
    const openWebFront = user.webFrontExpirationTime && moment(user.webFrontExpirationTime).isAfter(moment.now())
    return openWebApi || openWebFront
  }

  /**
   * 当前用户的项目编辑权限
   */
  get canEdit () {
    const user = getterUserInfo()
    return user.canCreateProjectGroup
  }
}
