/**
 * 项目组模块
 */

import DefaultApiService from '@/libs/api-request'
import request, { DataApiResult, PageDataApiResult, PagerQueryBean } from '@/libs/http-request'
import { UserProjectGroupStar } from './user-project-group-star'

/**
 * 项目组服务请求api接口
 */
class UserProjectGroupStarApi extends DefaultApiService<UserProjectGroupStar> {
  /**
   * 查询项目组请求接口
   * @param query 查询bean对象
   */
  userProjectGroupStarListOfCurTeam (query: PagerQueryBean): Promise<DataApiResult<Array<UserProjectGroupStar>>> {
    return this.request('userProjectGroupStarListOfCurTeamAndUserId', query) as any
  }

  star (data: UserProjectGroupStar): Promise<DataApiResult<UserProjectGroupStar>> {
    return this.request('star', data, 'POST')
  }

  unStar (data: UserProjectGroupStar): Promise<DataApiResult<UserProjectGroupStar>> {
    return this.request('unStar', data, 'POST')
  }
}

export default new UserProjectGroupStarApi('/api/user-project-group-star')
