/**
 * 项目组模块
 */

import DefaultApiService from '@/libs/api-request'
import request, { DataApiResult, PageDataApiResult, PagerQueryBean } from '@/libs/http-request'
import { ProjectGroup } from './project-group'

/**
 * 项目组服务请求api接口
 */
class ProjectGroupApi extends DefaultApiService<ProjectGroup> {
  /**
   * 查询项目组请求接口
   * @param query 查询bean对象
   */
  projectGroupListOfCurTeam (query: PagerQueryBean): Promise<PageDataApiResult<ProjectGroup>> {
    return this.queryByQueryBean('projectGroupListOfCurTeam', query)
  }

  lockOrUnLock (data: ProjectGroup): Promise<DataApiResult<ProjectGroup>> {
    return this.request('lockOrUnLock', data, 'POST')
  }
}

export default new ProjectGroupApi('/api/project-group')
