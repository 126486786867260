
import { BaseModal } from '@/libs/modal-helper'
import { Prop, Vue, Component } from 'vue-property-decorator'
import ProjectBaseModal from './project-base-modal.vue'
import { Form } from 'view-design'
import { ProjectGroup } from '@/api/project-group/project-group'
import _ from 'lodash'

@Component({
  name: 'ProjectGroupCreatorModal', components: { ProjectBaseModal }
})
export default class ProjectGroupCreatorModal extends Vue implements BaseModal {
  @Prop({
    type: String,
    default: '新建项目组'
  })
  title!: string

  @Prop({
    type: Object,
    default: () => {
      return {}
    }
  })
  dataProps!: ProjectGroup

  data: ProjectGroup = _.cloneDeep(this.dataProps)

  /// 表单校验规则
  rules={
    name: [
      { required: true, message: '项目组名称不能为空', trigger: 'blur' }
    ]
  }

  show (): void {
    (this.$refs.dlg as ProjectBaseModal).show()
  }

  close (): void {
    (this.$refs.dlg as ProjectBaseModal).close()
  }

  doSave (): void {
    const form = this.$refs.form as Form

    form.validate((result) => {
      if (result) {
        this.$emit('onOk', this.data)
      } else {
        this.$Notice.error({
          title: '错误',
          desc: '数据检查失败!'
        })
      }
    })
  }

  doCancel (): void {
    this.$emit('onCancel')
  }
}

