
import { ProjectGroup } from '@/api/project-group/project-group'
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'

const letters = ['ABC', 'DEF', 'GHI', 'JKL', 'MNO', 'PQR', 'STU', 'VWX', 'ZY', '其他']

export interface GroupInfo {
          groupTag: string;
          count: number;
          starCount: number;
          projectGroups: Array<ProjectGroup>;
}

@Component({
  name: 'GroupScrollerBar',
  components: { }
})
export default class GroupScrollerBar extends Vue {
    @Prop({
      type: Array,
      default: () => []
    })
    groupInfoList!: Array<GroupInfo>

    get groupInfoListOfWrap () {
      return letters.map(letter => {
        const groupInfo = this.groupInfoList.find(groupInfo => groupInfo.groupTag === letter)
        let count = 0
        let starCount = 0
        if (groupInfo) {
          count = groupInfo.count
          starCount = groupInfo.starCount
        }
        return {
          groupTag: letter,
          count: count,
          starCount: starCount
        }
      })
    }

    get totalStarCount () {
      return this.groupInfoList!.reduce((res, cur) => {
        const curTotal = res + cur.starCount
        return curTotal
      }, 0)
    }

    activeGroup = ''

    onClickGroup (groupInfo: GroupInfo) {
      if (groupInfo.count <= 0) {
        return
      }
      this.activeGroup = groupInfo.groupTag
      this.$emit('on-select-group', groupInfo)
    }

    onClickBackTop () {
      if (this.groupInfoList && this.groupInfoList.length > 0) {
        this.activeGroup = this.groupInfoList[0].groupTag
        this.$emit('on-select-group', this.groupInfoList[0])
      }
    }
}
