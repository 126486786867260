
import { ProjectGroup } from '@/api/project-group/project-group'
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import ProjectFolder from './project-folder.vue'

@Component({
  name: 'ProjectFolderGroup',
  components: { ProjectFolder }
})
export default class ProjectFolderGroup extends Vue {
    @Prop({
      type: String,
      default: 'ABC'
    })
    groupTag?: string

    @Prop({
      type: Array,
      default: () => []
    })
    folderList!: Array<ProjectGroup>

    onClickProjectGroup (folder: ProjectGroup) {
      this.$router.push({
        path: '/project/sub-project',
        query: {
          projectGroupId: folder.id!.toString(),
          projectGroupName: folder.name
        }
      })
    }
}

