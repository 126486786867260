import { ProjectGroup } from '@/api/project-group/project-group'
import { showModal } from '@/libs/modal-helper'
import ProjectGroupCreatorModal from './project-group-creator-modal.vue'

export default class Modals {
  static showProjectGroupCreatorModal (onOk?: (data: ProjectGroup) => Promise<void>) {
    showModal<any>(ProjectGroupCreatorModal, {
      props: {

      }
    }, true, onOk, undefined)
  }

  static showProjectGroupUpdaterModal (proectGroup: ProjectGroup, onOk?: (data: ProjectGroup) => Promise<void>) {
    showModal<any>(ProjectGroupCreatorModal, {
      props: {
        title: '修改项目组名称',
        dataProps: proectGroup
      }
    }, true, onOk, undefined)
  }
}
